import './App.css';
import React from "react";


const Error = () => {

  return (
    <div className='body-content'>
      <div className='error-content'>
        <div>
          <p>エラーが発生しました。</p>
          <p>LINEトーク画面に戻り再度開き直してください。</p>
          <p>もし再度開き直してもこのページが表示される場合は公式ラインよりご連絡ください。</p>
          <p>またブラウザがプライベートモードの設定の場合、エラーになる場合がありますので、プライベートモードを解除して再度開き直してください。</p>
        </div>
      </div>
    </div>
  );
};

export default Error;
