import './App.css';
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Select, ConfigProvider, Checkbox, Modal} from 'antd';
import { useNavigate,useLocation, Link } from 'react-router-dom';
import { industryArray,areaArray,postArray,genderArray, ageArray } from './common';

const { TextArea } = Input; 
const { Option } = Select;

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= 1900; i--) {
    years.push(i);
  }
  return years;
};

const generateMonthOptions = () => {
  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  return months;
};

const generateDayOptions = () => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }
  return days;
};


const ProfileForm = () => {
  const [userInfo, setUserInfo]  = useState(null);
  const [checkedItems, setCheckedItems] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [isEdit,setIsEdit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [isModal,setIsModal] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashLINEID = queryParams.get('id');
  useEffect(()=>{
    if(hashLINEID === null){
      navigate('/Error');
    }
    init();
    
  },[]);

  const init = async() =>{
    if(await checkLINELogin()){
      if(await startLogin()){
        await getUserInfo();
      }
      else{
        navigate('/Error');
      }
      
    }
    else{
      await startLINEAuth();
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
  };

  const handleFocus = () => {
    document.body.classList.add('no-scroll');
  };

  const handleBlur = () => {
    document.body.classList.remove('no-scroll');
  };

  useEffect(() => {
    const selectElements = document.querySelectorAll('.ant-select-selection-search input');
    selectElements.forEach(select => {
      select.addEventListener('focus', handleFocus);
      select.addEventListener('blur', handleBlur);
    });

    return () => {
      selectElements.forEach(select => {
        select.removeEventListener('focus', handleFocus);
        select.removeEventListener('blur', handleBlur);
      });
    };
  }, [userInfo]); 

  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
  };

  const allChecked = Object.values(checkedItems).every(Boolean);

  async function getUserInfo(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({userId:hashLINEID}),
      });

      const data = await response.json();
      if(data){
        if(data.isError){
          navigate("/Error")
        }
        else{
          if(data.userInfo !== null){
            let userInfo = data.userInfo;
            setIsEdit(userInfo.isEntry && userInfo.isAuth >= 0);
            setUserInfo(userInfo);
            setFormValues({
              lastName: userInfo.lastName,
              firstName: userInfo.firstName,
              companyName: userInfo.companyName,
              post: userInfo.post,
              gender: userInfo.gender,
              year : getBirthDatePart(userInfo.birthDate)[0],
              month : getBirthDatePart(userInfo.birthDate)[1],
              day : getBirthDatePart(userInfo.birthDate)[2],
              area: userInfo.area,
              industry: userInfo.industry,
              businessSummary: userInfo.businessSummary,
              introductionText: userInfo.introductionText,
              LINEURL: userInfo.LINEURL,
              filter_post:userInfo.filter_post,
              filter_gender:userInfo.filter_gender,
              filter_age:userInfo.age,
              filter_area:userInfo.area, 
              filter_industry:userInfo.filter_industry, 
              filter_freeword:userInfo.filter_freeword, 
            })
        }
        else{
          setUserInfo({
              lastName: '',
              firstName: '',
              companyName: '',
              post: '',
              gender: '',
              birthDate: '',
              area: '',
              industry: '',
              businessSummary: '',
              introductionText: '',
              LINEURL: '',
              filter_post:'',
              filter_gender:'',
              filter_age:'',
              filter_area:'', 
              filter_industry:'', 
              filter_freeword:'', 
          })
        }
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

function getBirthDatePart(birthDate){
  if(birthDate){
    const dateFormat = new Date(birthDate);
    return [dateFormat.getFullYear(), dateFormat.getMonth()+1,dateFormat.getDate() ];
  }
  else{
    return [null,null,null];
  }
}

async function startLogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLINEAuth(){
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
  const state = generateRandomString(8);
  const scope = 'profile openid';
  const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
  window.location.href = authUrl;
}

function generateRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

async function checkLINELogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({accessOringn:"/ProfileForm?id=",parameter:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}


  const onSubmit = async(values) => {
  const { year, month, day } = values;
  const birthDate = `${year}-${month}-${day}`;

    const postData = {
      hashLINEID: hashLINEID,
      lastName: values.lastName,
      firstName: values.firstName,
      companyName: values.companyName,
      post: values.post,
      gender: values.gender,
      birthDate: birthDate,
      area: values.area,
      industry: values.industry,
      businessSummary: values.businessSummary,
      introductionText: values.introductionText,
      LINEURL:values.LINEURL,
      filter_post:values.filter_post,
      filter_gender:values.filter_gender,
      filter_age:values.age,
      filter_area:values.area, 
      filter_industry:values.filter_industry, 
      filter_freeword:values.filter_freeword, 
    }
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/profile', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            navigate('/Error');
          }
          else{
            if(data.isEntry){
              navigate("/Success")
            }
            else{
              navigate("/SuccessFirst")
            }
          }
        }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  };

  const onKeep = async() => {
    const values = formValues;
    const { year, month, day } = values;
    const birthDate = year && month && day ? `${year}-${month}-${day}` : null; 
  
      const postData = {
        hashLINEID: hashLINEID,
        lastName: values.lastName,
        firstName: values.firstName,
        companyName: values.companyName,
        post: values.post === "" ? null : values.post,
        gender: values.gender === "" ? null : values.gender,
        birthDate: birthDate,
        area: values.area === "" ? null : values.area,
        industry: values.industry === "" ? null : values.industry,
        businessSummary: values.businessSummary,
        introductionText: values.introductionText,
        LINEURL:values.LINEURL,
        filter_post:values.filter_post,
        filter_gender:values.filter_gender,
        filter_age:values.age,
        filter_area:values.area, 
        filter_industry:values.filter_industry, 
        filter_freeword:values.filter_freeword, 
      }
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/keep', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData),
          });
  
          const data = await response.json();
          if(data){
            if(data.isError){
              navigate('/Error');
            }
            else{
              alert("保存しました")
            }
          }
      } catch (error) {
          navigate('/Error');
          console.error('Fetch error:', JSON.stringify(error));
      }
    };

    const handleSearch = () => {
      setIsModal(true);
    }

    const handleCancel = () => {
      setIsModal(false);
    }
  
    const handleReset = () => {
      form.resetFields();
    }

  return (
    <>

    {userInfo !== null &&
      <div className='main-content'>
        <div>
        <h2 className='page-title'>プロフィール登録</h2>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Input: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Radio:{
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Select: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Checkbox: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
              },
            }}
          >
            <div style={{color:"red",textAlign:"end",fontSize:"90%"}}>＊必須項目</div>
                <Form
                  name="form"
                  className="page-form"
                  onFinish={onSubmit}
                  onValuesChange={handleValuesChange}
                >
                <div style={{display:"flex",justifyContent:"space-around"}}>
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: '苗字を入力してください' }]}
                    label={<span style={{fontSize:"120%"}}>姓</span>}
                    style={{display: 'inline-block', width: '50%', paddingRight: '8px' }}
                    labelCol={{span:24}}
                    initialValue={userInfo.lastName}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="text"
                    />
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: '名前を入力してください' }]}
                    label={<span style={{fontSize:"120%"}}>名</span>}
                    style={{display: 'inline-block', width: '50%'}}
                    labelCol={{span:24}}
                    initialValue={userInfo.firstName}
                  >
                    <Input
                      style={{fontSize:"120%"}}
                      type="text"
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  name="companyName"
                  label={<span style={{fontSize:"120%"}}>会社名・屋号</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.companyName}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="post"
                  rules={[{ required: true, message: '役職を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>役職</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.post}
                >
                  <Radio.Group name="post">
                    {postArray.map((item) => (
                        <Radio value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="gender"
                  rules={[{ required: true, message: '性別を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>性別</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.gender}
                >
                  <Radio.Group name="gender">
                    {genderArray.map((item) => (
                        <Radio value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label={<span style={{fontSize:"120%"}}>生年月日</span>} required colon={false} style={{marginBottom:"0"}} labelCol={{span:24}}>
                  <Form.Item
                    name="year"
                    rules={[{ required: true, message: '年を選択してください' }]}
                    style={{ display: 'inline-block', width: '33%', paddingRight: '8px' }}
                    initialValue={userInfo.birthDate !== "" && userInfo.birthDate !== null ? (new Date(userInfo.birthDate)).getFullYear() : ""}
                  >
                    <Select placeholder="年">
                      {generateYearOptions().map((year) => (
                        <Option key={year} value={year}>{year}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="month"
                    rules={[{ required: true, message: '月を選択してください' }]}
                    style={{ display: 'inline-block', width: '33%', paddingRight: '8px' }}
                    initialValue={userInfo.birthDate !== "" && userInfo.birthDate !== null ? (new Date(userInfo.birthDate)).getMonth() + 1 : ""}
                  >
                    <Select placeholder="月">
                      {generateMonthOptions().map((month) => (
                        <Option key={month} value={month}>{month}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="day"
                    rules={[{ required: true, message: '日を選択してください' }]}
                    style={{ display: 'inline-block', width: '33%' }}
                    initialValue={userInfo.birthDate !== "" && userInfo.birthDate !== null ? (new Date(userInfo.birthDate)).getDate() : ""}
                  >
                    <Select placeholder="日" >
                      {generateDayOptions().map((day) => (
                        <Option key={day} value={day}>{day}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                {/* <div style={{textAlign:"end",fontSize:"90%"}}>
                  <div style={{color:"red"}}>生年月日の情報が他ユーザーに公開されることはありません</div>
                </div> */}
                <Form.Item
                  name="area"
                  rules={[{ required: true, message: '活動拠点を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>活動拠点</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.area}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                    >
                        {areaArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="industry"
                  rules={[{ required: true, message: '業種を選択してください' }]}
                  label={<span style={{fontSize:"120%"}}>業種</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.industry}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                    >
                        {industryArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="businessSummary"
                  rules={[{ required: true, message: '事業概要を入力してください' }]}
                  label={<span style={{fontSize:"120%"}}>事業概要</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.businessSummary}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                    name="introductionText"
                    rules={[{ required: true, message: '自己紹介文を入力してください' }]}
                    label={<span style={{fontSize:"120%"}}>自己紹介文</span>}
                    labelCol={{span:24}}
                    initialValue={userInfo.introductionText}
                  >
                  <TextArea rows={5}></TextArea>
                </Form.Item>
                <Form.Item
                  name="LINEURL"
                  // rules={[{ required: true, message: 'LINEのURLを入力してください' }]}
                  label={<span style={{fontSize:"120%"}}>LINEのURL</span>}
                  labelCol={{span:24}}
                  initialValue={userInfo.LINEURL}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="text"
                  />
                </Form.Item>
                <div style={{textAlign:"end",fontSize:"90%",marginBottom:"20px"}}>
                  <div style={{color:"red"}}>LINEのURLが他ユーザーに公開されることはありません</div>
                  <Link to="https://guide.line.me/ja/friends-and-groups/add-qrurl.html" style={{color: '#07DFD8',textDecoration:"underline"}}>LINEのURLの取得方法はこちら</Link>
                </div>
                {userInfo.plan >0 &&
                  <div style={{textAlign:"center"}}>
                    <Button type="primary" style={{margin:"5px 0"}} onClick={() => handleSearch()}>
                      繋がりたい方を選択
                    </Button>
                  </div>
                }

                <Modal
                  open={isModal}
                  onCancel={() => handleCancel()}
                  footer={[
                    <>
                      <div style={{textAlign:"start"}}>
                      <Form form={form}>
                        <Form.Item
                          name="post"
                          label={<span style={{fontSize:"120%"}}>役職</span>}
                          labelCol={{span:24}}
                        >
                          <Select
                              style={{fontSize:"120%", width: '100%'}}
                              allowClear
                              initialValue={userInfo.filter_post}
                          >
                            {postArray.map((item) => (
                                <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="gender"
                          label={<span style={{fontSize:"120%"}}>性別</span>}
                          labelCol={{span:24}}
                        >
                            <Select
                                style={{fontSize:"120%", width: '100%'}}
                                allowClear
                                initialValue={userInfo.filter_gender}
                            >
                              {genderArray.map((item) => (
                                  <Option value={item.key}>{item.value}</Option>
                              ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                          name="age"
                          label={<span style={{fontSize:"120%"}}>年代</span>}
                          labelCol={{span:24}}
                        >
                            <Select
                                style={{fontSize:"120%", width: '100%'}}
                                allowClear
                                initialValue={userInfo.filter_age}
                            >
                              {ageArray.map((item) => (
                                  <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                              ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                          name="area"
                          label={<span style={{fontSize:"120%"}}>活動拠点</span>}
                          labelCol={{span:24}}
                        >
                            <Select
                                style={{fontSize:"120%", width: '100%'}}
                                allowClear
                                initialValue={userInfo.filter_area}
                            >
                                {areaArray.map((item) => (
                                    <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                          name="industry"
                          label={<span style={{fontSize:"120%"}}>業種</span>}
                          labelCol={{span:24}}
                        >
                            <Select
                                style={{fontSize:"120%", width: '100%'}}
                                allowClear
                                initialValue={userInfo.filter_industry}
                            >
                                {industryArray.map((item) => (
                                    <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                          name="free"
                          label={<span style={{fontSize:"120%"}}>フリーワード</span>}
                          labelCol={{span:24}}
                        >
                          <Input
                            style={{fontSize:"120%"}}
                            initialValue={userInfo.filter_freeword}
                            type="text"
                          />
                        </Form.Item>
                        </Form>
                        </div>
                        <Button type='primary' onClick={() => handleCancel()}>
                          OK
                        </Button>
                        <Button key="cancel" onClick={() => handleReset()}>
                          リセット
                        </Button>
                      </>
                    ]}
                  >
                </Modal>
                {!isEdit &&
                  <>
                    <div style={{marginBottom:"20px"}}>
                    <Checkbox
                      name="checkbox1"
                      checked={checkedItems.checkbox1}
                      onChange={handleCheckboxChange}
                    >
                      過度な営業・勧誘行為は行いません
                    </Checkbox>
                    </div>
                    <div style={{marginBottom:"20px"}}>
                    <Checkbox
                      name="checkbox2"
                      checked={checkedItems.checkbox2}
                      onChange={handleCheckboxChange}
                    >
                      宗教・MLM等の勧誘は行いません
                    </Checkbox>

                    </div>
                    <div style={{marginBottom:"20px"}}>
                      <div style={{background:"#fff",height:"100px",overflowY:"scroll",padding:"5px"}}>
                        <h3>個人情報の取り扱いに関する同意事項</h3>
                        <h4>1. 個人情報の定義</h4>
                        <div>本サービスにおける個人情報とは、氏名、生年月日、性別、などの個人を識別できる情報を指します。</div>
                        <h4>2. 個人情報の利用目的</h4>
                        <div>ご提供いただいた個人情報は、以下の目的で利用いたします。</div>
                        <div>・他のユーザーへのプロフィール情報の公開</div>
                        <div>・ユーザーサポート、問い合わせ対応のため</div>
                        <div>・サービス向上のためのデータ分析および統計処理</div>
                        <h4>3. プロフィール情報の公開</h4>
                        <div>ユーザーが本サービスで提供するプロフィール情報（LINEURL、生年月日以外の上記で入力した内容）は、他のユーザーに対して公開されます。ただし、次の情報は他のユーザーに公開されません。</div>
                        <div>・LINEのアカウントに関連する情報</div>
                        <div>生年月日は公開されませんが、年代(20代、30代など)の情報は公開されます。</div>
                        <div>本サービスの公式LINEアカウントをブロックすることで他ユーザーへのプロフィール情報の公開が停止されます。</div>
                        <h4>4. 第三者への提供</h4>
                        <div>ご提供いただいた個人情報は、以下の場合を除き、ユーザーの同意なく第三者に提供することはありません。</div>
                        <div>・ユーザーの同意がある場合</div>
                        <div>・法令に基づく場合</div>
                        <div>・ユーザー保護やサービス運営のため、必要な範囲内で業務委託先に提供する場合</div>
                        <h4>5. 個人情報の管理</h4>
                        <div>ご提供いただいた個人情報は、適切な管理のもとで安全に保管され、個人情報の漏洩、紛失、改ざんなどを防止するため、必要かつ適切な措置を講じます。</div>
                        <h4>6. 個人情報の開示、訂正、削除</h4>
                        <div>ユーザーは、サービス内の設定を通じて、自己の個人情報を確認・修正・削除することができます。その他の開示、訂正、削除の要求がある場合は、サポート窓口にて対応いたします。</div>
                        <h4>7. 利用停止および退会</h4>
                        <div>ユーザーが本アプリの利用を停止または退会する場合、一定の期間を経てすべての個人情報が削除されます。ただし、法令に基づく保存義務がある場合はこの限りではありません。</div>
                        <h4>8. 同意の確認</h4>
                        <div>以下のチェックボックスにチェックを入れることで、上記の個人情報の取り扱いに関する内容に同意いただいたものとみなします。</div>
                      </div>
                      <div style={{marginTop:"5px"}}>
                        <Checkbox
                          name="checkbox3"
                          checked={checkedItems.checkbox3}
                          onChange={handleCheckboxChange}
                        >
                          上記の個人情報の取り扱いに関する内容に同意します
                        </Checkbox>
                      </div>
                    </div>
                  </>
                }
                <div className="form-button">
                  {!isEdit &&
                    <Button type="primary" onClick={onKeep} style={{marginRight:"10px"}}>
                      一時保存
                    </Button>
                  } 
                  <Button type="primary" htmlType="submit" disabled={!allChecked && !isEdit}>
                    {!isEdit ? (<>登録</>) : (<>更新</>)}
                  </Button>
                </div>
              </Form>
          </ConfigProvider>
        </div>
      </div>
    }
  </>
  );
};

export default ProfileForm;
