import './App.css';
import React from "react";


const SuccessFirst = () => {

  return (
    <div className='body-content'>
      <div className='error-content'>
        <div>
          <p>プロフィール登録が完了しました、プロフィールが承認されるまでしばらくお待ちください。</p>
          <p>プロフィールが承認されましたら、フリープランへの登録完了となります。</p>
          <p>再度プロフィール編集を行う場合は、LINEトーク画面の下部メニューから「プロフィール編集」をタップしてください。</p>
          <p>ご不明点やシステムの不具合などがございましたら、公式ラインよりご連絡ください。</p>
        </div>
      </div>
    </div>

  );
};

export default SuccessFirst;
